.Highlights {
  @apply container mx-auto grid grid-cols-1 items-start justify-center align-middle lg:grid-cols-2 lg:space-x-10;
}

.Highlights__Wrapper {
  @apply px-4 py-8;
}

.Highlights__Wrapper__Title {
  @apply text-3xl font-bold 2xl:text-4xl;
}

.Highlights__Wrapper__Reviews {
  @apply my-2 text-base font-normal;
}

.Highlights__Wrapper__Title_mobile {
  composes: Highlights__Wrapper__Title;
  @apply mb-2 mt-6 block px-4 lg:hidden;
}

.Highlights__Wrapper__Title_desktop {
  composes: Highlights__Wrapper__Title;
  @apply my-4 hidden lg:block;
}

.Highlights__Wrapper__Quote {
  @apply mb-4 font-normal;
}

.Highlights__Wrapper__Quote__Text {
  @apply text-lg 2xl:text-xl;
}

.Highlights__Wrapper__Quote__Author {
  @apply italic text-black;
}

.Highlights__Wrapper > ul {
  @apply my-4 space-y-4;
}

.Highlights__Wrapper > ul > li {
  @apply text-lg;
}

.Highlights__Wrapper > p {
  @apply mb-6 text-lg lg:mb-4;
}

.Highlights__Wrapper__ListItem {
  @apply pl-1 font-bold rtl:pr-1;
}

.Highlights__BuyButtonWrapper {
  @apply text-center;
}

.Highlights__BuyButton {
  @apply hover:no-underline mx-auto my-4 cursor-pointer block w-full min-w-[75%] rounded-lg px-2 py-4 text-center text-xl font-bold uppercase sm:inline-block sm:w-auto sm:px-8 sm:text-2xl lg:min-w-full xl:min-w-[75%];
}

.Highlights__Shipping {
  @apply mx-auto text-center;
}

.Highlights__Shipping > img {
  @apply mx-auto;
}

.PromoEvent {
  @apply mx-auto block max-w-fit text-center;
}
